<template>
    <v-form @submit.prevent="createTeam">
      <v-text-field v-model="team.name" label="Name"></v-text-field>
      <v-select v-model="team.sport" :items="sports" label="Sport"></v-select>
      <v-text-field v-model="team.code" label="Team Code"></v-text-field>
      <v-text-field v-model="team.colour" label="Team Colour"></v-text-field>
      <v-btn type="submit" color="primary">Create Team</v-btn>
    </v-form>
  </template>
  
  <script>
  export default {
    data() {
      return {
        team: {
          name: '',
          sport: '',
          code: '',
          colour: '#'
        },
        sports: ['Football', 'Hurling', 'Rugby', 'Soccer']
      };
    },
    methods: {
      createTeam() {
        this.$store.dispatch('createTeam', this.team);
      }
    }
  };
  </script>
  